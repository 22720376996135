import React from 'react';

const HeaderTempJhome = () => {
    return (
        <nav className="container">
            <div className="alert alert-info w-100 m-0 p-2 mt-2 mb-2">
                переезжаем на новый домен <a href={"https://jhome.online/"}>jhome.online</a>
            </div>
        </nav>);
};

export default HeaderTempJhome;